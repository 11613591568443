import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const globalStore = useGlobalStore()
  const runtimeConfig = useRuntimeConfig()

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'config',
    rr_store_id: globalStore.store.id,
    rr_store_host: window.location.host,
    rr_store_handle: globalStore.store.handle,
  })

  runtimeConfig.public.GTM_CONTAINER_ID &&
    nuxtApp.vueApp.use(
      createGtm({
        id: runtimeConfig.public.GTM_CONTAINER_ID,
        defer: true,
        trackOnNextTick: false,
        vueRouter: router,
        vueRouterAdditionalEventData: () => ({
          rr_store_id: globalStore.store.id,
          rr_store_host: window.location.host,
          rr_store_handle: globalStore.store.handle,
        }),
        enabled: true,
      }),
    )
})
