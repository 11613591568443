import { useRouteQuery } from '@vueuse/router'
import { datadogRum } from '@datadog/browser-rum'
import { PromotionTarget, type Promotion } from '@rediredi/types'
import { IStorefrontItem } from '~/types/item'
import { Facet } from '~/types/api'

export const useItemsStore = defineStore('items', {
  state: () => ({
    query: useRouteQuery('q') as Ref<string | undefined>,
    term: useRouteQuery('c') as Ref<string | undefined>,
    promoId: useRouteQuery('promoId') as Ref<string | undefined>,
    facetBy: ref<Facet | null>(null),
    perPage: 20,
    totalRecords: undefined as number | undefined,
    currentPage: 1,
    scrollPosition: 0,
    items: [] as IStorefrontItem[],
    facets: [] as Facet[],
    hasInitiallyLoaded: false,
    hasPromotions: false,
    filterByPromotion: false,
    currentPromotion: null as Promotion | null,
  }),

  getters: {
    sortedFacets: (state) => {
      return state.facets.sort((a, b) => a.value.localeCompare(b.value))
    },
    sortedParents: (state) => {
      const parents: Facet[] = []
      const chields: Facet[] = state.facets.filter((facet) => !!facet.parent)

      state.facets.forEach((facet) => {
        if (parents.find((parent) => parent.id === facet.parent?.id || parent.id === facet.id))
          return
        parents.push({
          ...(facet.parent || facet),
          hasChildren:
            !!chields.find(({ parent }) => (parent ? parent.id === facet.id : false)) ||
            !!facet.parent,
        })
      })

      return parents.sort((a, b) => a.value.localeCompare(b.value))
    },
    childrenFacets: (state) => {
      return (parentId: string) => {
        return state.facets
          .filter((facet) => facet.parent?.id === parentId)
          .sort((a, b) => a.value.localeCompare(b.value))
      }
    },
    facetsValueTree: (state) => {
      if (!state.facetBy) return []
      const tree = []

      if (state.facetBy.parent) tree.push(state.facetBy.parent?.value)
      tree.push(state.facetBy.value)

      return tree
    },
    filterBy: (state) => {
      const globalStore = useGlobalStore()

      const filters = []

      if (state.filterByPromotion) {
        filters.push(`hasActivePromotion:=true`)
      }

      if (state.currentPromotion) {
        filters.push(`promotionId:=${state.currentPromotion.id}`)
      }

      if (!globalStore.store.displayOutOfStockItems) {
        filters.push('quantityAvailable:>0')
      }

      return filters.join('&&')
    },
  },

  actions: {
    clearOnSearch() {
      this.currentPage = 1
      this.scrollPosition = 0
      this.items = []
      this.term = undefined
      this.query = undefined
      this.filterByPromotion = false
      this.currentPromotion = null
      this.promoId = undefined
    },

    async searchItems() {
      try {
        const params = {
          sortBy: 'title.asc',
          perPage: this.perPage.toString(),
          page: this.currentPage.toString(),
          query: this.query || this.facetBy?.id || '',
          filterBy: this.filterBy,
        }

        const response = await useFetchWrapper<SearchResponse<IStorefrontItem>>(
          `/inventory/storefront/items?${new URLSearchParams(params)}`,
          'GET',
        )

        return response
      } catch (error) {
        datadogRum.addError(error)
      }
    },

    async loadPromotion() {
      const response = await useFetchWrapper<Promotion>(
        `/promotions/storefront/promotions/${this.promoId}`,
        'GET',
      )
      this.currentPromotion = response || null

      if (response.target === PromotionTarget.Category) {
        const category = response.category

        if (category?.parent) {
          this.term = `${category?.parent.name}_${category?.name}`
        } else {
          this.term = category?.name
        }
      }

      return response
    },

    async loadFacetsV2() {
      const qs = {
        facetBy: 'promotion',
        perPage: '0',
      }

      const response = await useFetchWrapper<SearchResponse<IStorefrontItem>>(
        `/inventory/storefront/v2/items?${new URLSearchParams(qs)}`,
        'GET',
      )

      this.facets = response.meta.facets?.category || []
      this.hasPromotions = !!response.meta.facets?.promotion?.length

      this.totalRecords = response.meta.total

      return response
    },

    async loadFacets() {
      const response = await useFetchWrapper<SearchResponse<IStorefrontItem>>(
        `/inventory/storefront/items`,
        'GET',
      )

      this.facets = response.meta.facets

      this.totalRecords = response.meta.total

      return response
    },
  },
  hydrate(state, _) {
    // @ts-ignore next-line
    state.term = useRouteQuery('c')
    // @ts-ignore next-line
    state.query = useRouteQuery('q')
    // @ts-ignore next-line
    state.promoId = useRouteQuery('promoId')
  },
})
