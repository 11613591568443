import { UnleashClient, plugin as unleashPlugin } from '@unleash/proxy-client-vue'

export default defineNuxtPlugin((nuxtApp) => {
  const globalStore = useGlobalStore()
  const runtimeConfig = useRuntimeConfig()
  const featureToggleConfig = {
    url: runtimeConfig.public.UNLEASH_URL,
    clientKey: runtimeConfig.public.UNLEASH_CLIENT_KEY,
    refreshInterval: Number(runtimeConfig.public.UNLEASH_REFRESH_INTERVAL),
    appName: runtimeConfig.public.UNLEASH_APP_NAME,
    environment: import.meta.env.MODE,
  }

  const unleashClient = new UnleashClient(featureToggleConfig)
  nuxtApp.vueApp.use(unleashPlugin, { unleashClient })
  // @ts-ignore
  nuxtApp.$i18n.setLocale(globalStore.store.locale!)
})
