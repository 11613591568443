import revive_payload_client_4sVQNw7RlN from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/rediredi-storefront/rediredi-storefront/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/rediredi-storefront/rediredi-storefront/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_locale_client_slenZJp2bM from "/home/runner/work/rediredi-storefront/rediredi-storefront/plugins/01.locale.client.ts";
import _02_unleash_client_x9IxGJgRDx from "/home/runner/work/rediredi-storefront/rediredi-storefront/plugins/02.unleash.client.ts";
import _03_datadog_client_qmxLFXny3I from "/home/runner/work/rediredi-storefront/rediredi-storefront/plugins/03.datadog.client.ts";
import _04_analytics_client_zWXixQHSLi from "/home/runner/work/rediredi-storefront/rediredi-storefront/plugins/04.analytics.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _01_locale_client_slenZJp2bM,
  _02_unleash_client_x9IxGJgRDx,
  _03_datadog_client_qmxLFXny3I,
  _04_analytics_client_zWXixQHSLi
]