type useFetchType = typeof useFetch

export const useApiFetch: useFetchType = (request, opts) => {
  const currentStore = useCurrentStore()
  const config = useRuntimeConfig()

  return useFetch(request, {
    headers: {
      'X-RR-Store-ID': currentStore.id ?? '',
    },
    baseURL: config.public.API_URL,
    ...opts,
  })
}

export const useFetchWrapper = <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: object,
) => {
  const currentStore = useCurrentStore()
  const config = useRuntimeConfig()
  const options: Record<string, string | object | undefined> = {
    method,
    headers: {
      'X-RR-Store-ID': currentStore.id ?? '',
    },
  }

  if (['POST', 'PATCH', 'PUT'].includes(method)) {
    options.body = body
  }

  return $fetch<T>(`${config.public.API_URL}${url}`, options)
}
