import { emit } from 'process'; import { RBaseButton } from '#build/components';
<script lang="ts" setup>
const props = defineProps<{ isOpen: boolean }>()
const emit = defineEmits(['close', 'confirm'])

const { t } = useI18n()
const isMinor = ref(false)
const globalStore = useGlobalStore()

const setUserAdultStatus = () => {
  globalStore.config.isAdult = true
  emit('close')
}
</script>

<template>
  <RBaseModal
    :has-background="false"
    blur-page-content
    v-bind="props"
    class="!w-[490px] py-12 px-8 shadow-xl"
    @close="emit('close')"
  >
    <div v-if="!isMinor" class="text-center flex flex-col gap-8">
      <h1 class="primary-text text-[32px] font-bold">
        {{ t('components.matureContentModal.title') }}
      </h1>

      <div class="flex justify-center items-center gap-6">
        <RBaseButton
          class="!w-20 py-3 px-4 uppercase text-sm font-semibold"
          color="primary"
          @click="setUserAdultStatus"
        >
          {{ t('components.matureContentModal.yes') }}
        </RBaseButton>
        <RBaseButton
          class="!w-20 py-3 px-4 uppercase text-sm font-semibold"
          color="primary"
          @click="isMinor = true"
        >
          {{ t('components.matureContentModal.no') }}
        </RBaseButton>
      </div>
    </div>
    <div v-else class="text-center flex flex-col gap-8">
      <h1 class="primary-text text-[32px] font-bold">
        {{ t('components.matureContentModal.minor.title') }}
      </h1>

      <p class="text-xl font-bold text-gray-400">
        {{ t('components.matureContentModal.minor.text') }}
      </p>
    </div>
  </RBaseModal>
</template>

<style scoped>
.primary-text {
  color: var(--color-primary);
}
</style>
