import { Currency, IItem, IMoney } from '@rediredi/types'
import type { CartItem } from '~/types/cart'

export const itemToAnalytics = (item: IItem) => {
  return {
    item_id: item.id,
    item_name: item.baseVariant.title,
    item_brand: item.brand,
    item_category: item.category?.name,
    price: item.baseVariant.price?.amount || 0,
    currency: item.baseVariant.price?.currency || Currency.BRL,
    quantity: 1,
  }
}

const cartItems = (items: CartItem[]) => {
  return items.map((i) => ({
    item_id: i.id,
    item_name: i.title,
    price: i.price?.amount || 0,
    quantity: i.quantity,
    currency: i.price?.currency || Currency.BRL,
  }))
}

const cartItemBaseEvent = (item: CartItem, items: CartItem[]) => {
  return {
    currency: item.price?.currency || Currency.BRL,
    value: item.price?.amount || 0,
    items: cartItems(items),
  }
}

export const getAddToCartEventPayload = (item: CartItem, items: CartItem[]) => {
  return cartItemBaseEvent(item, items)
}

export const getRemoveFromCartEventPayload = (item: CartItem, items: CartItem[]) => {
  return cartItemBaseEvent(item, items)
}

export const getViewCartEventPayload = (items: CartItem[], total: IMoney) => {
  return {
    value: total.amount,
    currency: total.currency,
    items: cartItems(items),
  }
}

export const getPurchaseEventPayload = (order: IStorefrontOrder) => {
  return {
    currency: order.itemPriceTotal?.currency || Currency.BRL,
    transaction_id: order.id,
    value: order.orderTotal?.amount || order.itemPriceTotal?.amount || 0,
    items: order.items.map((item) => ({
      item_id: item.id,
      item_name: item.title,
      price: item.itemPrice?.amount,
      currency: item.itemPrice?.currency,
      quantity: item.quantityOrdered,
    })),
  }
}
