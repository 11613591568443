<script lang="ts" setup>
export type BaseModalProps = {
  isOpen: boolean
  isCloseable?: boolean
  isCloseableOnEsc?: boolean
  blurPageContent?: boolean
  hasBackground?: boolean
  class?: string
}

const modalRef = ref()
const emit = defineEmits(['close'])
const globalStore = useGlobalStore()
const props = withDefaults(defineProps<BaseModalProps>(), {
  hasBackground: true,
  blurPageContent: false,
  class: '',
})

if (process.client) {
  const handleOverlay = () => {
    if (props.blurPageContent) {
      globalStore.blurredContent = props.isOpen
    }

    const el = document.querySelector('#layout-container') as HTMLElement

    el.style.overflow = props.isOpen ? 'hidden' : 'visible'
  }

  onMounted(() => {
    handleOverlay()
  })

  onBeforeUnmount(() => {
    if (props.isOpen) {
      const el = document.querySelector('#layout-container') as HTMLElement

      el.style.overflow = 'visible'
    }
  })

  watch(
    () => props.isOpen,
    () => handleOverlay(),
  )
}
</script>

<template>
  <ClientOnly>
    <Teleport to="#modals">
      <div
        v-if="props.isOpen"
        role="alertdialog"
        aria-modal="true"
        class="fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center"
        :class="{ 'modal-wrapper': props.hasBackground }"
        @click.stop
      >
        <div
          ref="modalRef"
          class="modal-content bg-white p-4 rounded-[20px] w-[624px] mx-4"
          :class="props.class"
        >
          <div v-if="props.isCloseable" class="modal-header relative mb-4">
            <button class="close-button absolute right-0 top-0" @click.prevent.stop="emit('close')">
              <RIcon size="22" name="ic:round-close" />
            </button>
          </div>

          <slot />
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<style scoped>
.modal-wrapper {
  background-color: #00000066;
}
.close-button {
  color: var(--color-primary);
}
</style>
