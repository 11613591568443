import { useGtm } from '@gtm-support/vue-gtm'

export const useEvent = () => {
  const gtm = useGtm()
  const globalStore = useGlobalStore()

  const event = (name?: string, value: object = {}, key = 'ecommerce') => {
    const enhancedPayload: Record<string, string> = { ...value }

    if (globalStore.store) {
      enhancedPayload.rr_store_id = globalStore.store.id ?? ''
      enhancedPayload.rr_store_host = window.location.host
      enhancedPayload.rr_store_handle = globalStore.store.handle ?? ''
    }

    gtm?.trackEvent({ event: name, [key]: enhancedPayload })
  }

  return {
    event,
  }
}
