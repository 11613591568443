export type ImageFormat = 'jpeg' | 'png' | 'webp' | 'jpg'

export type ImageSize = 'thumb' | 'small' | 'medium' | 'large'

export const optimizedImage = (src: string, size: ImageSize, format: ImageFormat = 'webp') => {
  if (!src.includes('rediredi.com')) return src

  const qs = new URLSearchParams({
    s: size,
    f: format,
  })

  return `${src}?${qs}`
}
