export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"google","content":"notranslate"}],"link":[{"rel":"preconnect","href":"https://www.google-analytics.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"preconnect","href":"https://eu.app.unleash-hosted.com"},{"rel":"preconnect","href":"https://www.google-analytics.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://fonts.googleapis.com"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"translate":"no"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'