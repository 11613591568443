import { datadogRum } from '@datadog/browser-rum'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const isDevOrProd = ['production', 'development'].includes(import.meta.env.MODE)
  const isLocal = runtimeConfig.public.APP_VERSION === 'local'

  !isLocal &&
    datadogRum.init({
      applicationId: runtimeConfig.public.DATADOG_APPLICATION_ID,
      clientToken: runtimeConfig.public.DATADOG_CLIENT_TOKEN,
      site: runtimeConfig.public.DATADOG_SITE,
      service: runtimeConfig.public.DATADOG_SERVICE,
      env: import.meta.env.MODE,
      sessionSampleRate: isDevOrProd ? 2 : 0,
      sessionReplaySampleRate: 20,
      trackUserInteractions: isDevOrProd,
      trackResources: isDevOrProd,
      trackLongTasks: isDevOrProd,
      version: runtimeConfig.public.APP_VERSION,
      defaultPrivacyLevel: 'allow',
    })

  isDevOrProd && datadogRum.startSessionReplayRecording()
})
